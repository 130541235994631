import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: [],
    sources: [],
    formats: [],
  },
  getters: {},
  mutations: {
    ITEMS(state, value) {
      state.items = value
    },
  },
  actions: {
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/campaigns', { params: { ...queryParams, admin: true } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/item/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/admin/campaigns',
          data: itemData,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    actionOnItem(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: payload.path,
          data: payload.item,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/campaigns/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendItem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/campaigns/send/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries({ state }, options) {
      axios
        .get('/countries', { params: options })
        .then(response => {
          state.pays = response.data.data
        })
        .catch(error => console.log(error))
    },
    fetchStatuses({ state }, options) {
      axios
        .get('/statuses', { params: options })
        .then(response => {
          state.statuses = response.data.data
        })
        .catch(error => console.log(error))
    },
    fetchSources({ state }, options) {
      axios
        .get('/sources', { params: options })
        .then(response => {
          state.sources = response.data.data
        })
        .catch(error => console.log(error))
    },
    fetchFormats({ state }, options) {
      axios
        .get('/formats', { params: options })
        .then(response => {
          state.formats = response.data.data
        })
        .catch(error => console.log(error))
    },
  },
}
